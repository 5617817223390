.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: hidden;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }

    .image-container {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        .portfolio-image {
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 100px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -90px;
        }
        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }
        .desc {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
            opacity: .01;
        }
        &:after{
            content: "";
            background: linear-gradient(180deg, #6692d4, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }
        &:hover:after {
            opacity: .85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
        &:hover .desc {
            opacity: 1;
        }
    }
}