.home-page {
    .text-zone{
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;

    }
    h1{
        color: #fff;
        font-size: 80px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
        font-weight: bold;
        word-spacing: 1px;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
        img{
            width: 100px;
            margin-left: 40px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }
}

h2{
    
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 15px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;               
}

.flat-button{
    color: #ffd700;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
        background: #ffd700;
        color: #333;
    }
}