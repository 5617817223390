/* Importing google font  */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap');

.tagcloud{
 left: 600px;
 bottom: 700px;
 font-family: 'Inter', sans-serif;
 font-weight: 600;
 letter-spacing: 0.0625em;
 font-size: 1.3em;
}



/* Change color of each text in sphere on hover   */
.tagcloud--item {
    color: rgb(226, 226, 226);
}

.tagcloud--item:hover {
    color: #ffd700;
    text-transform: uppercase;
}
            
        